import { SET_SNACKBAR } from "../constants/ActionTypes";

export function setSnackBar(open, variant, message){
    return{
        type: SET_SNACKBAR,
        payload: {
            open,
            variant,
            message
        }
    }
}