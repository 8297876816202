import React from "react";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import CustomSideMenu from "../../components/menus/CustomSideMenu";
import MaterialTable from "material-table";
import CustomDialog from "./../../components/menus/CustomDialog";
import { forwardRef } from "react";
import { getAllExpiringLicensesHistory } from "../../actions/LicenseHistoryActions";
import { setSnackBar } from "../../actions/SnackBarActions";
import { setDialog } from "../../actions/DialogActions";
import { JWT_TOKEN } from "../../constants/Config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class ExpirationManagementPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			licenses: [],
		};
	}

	componentDidMount() {
		if (!sessionStorage.getItem(JWT_TOKEN)) {
			this.props.history.push("/login");
		} else {
			this.loadExpirationData();
		}
	}

	openDialog = (rowData, value) => {
		this.props.setDialog(value, rowData);
	};

	loadExpirationData = () => {
		const proc = async () => {
			await trackPromise(this.props.getAllExpiringLicensesHistory());
		};
		proc().then((res) => {
			if (!sessionStorage.getItem(JWT_TOKEN)) {
				this.props.history.push("/login");
			} else {
				try {
					var dateNow = moment().format("YYYY-MM-DD");
					var dateBound = moment().add(1, "months").format("YYYY-MM-DD");

					var result = this.props.expiringLicensesHistory.map(function (el) {
						var obj = Object.assign({}, el);
						var expire = moment(obj.expireDate).format("YYYY-MM-DD");
						var maintenance = moment(obj.maintenanceDate).format("YYYY-MM-DD");
						var licenseExpiring = null;

						if (expire > maintenance && maintenance >= dateNow && maintenance <= dateBound) {
							licenseExpiring = false;
						} else if (expire > maintenance && expire >= dateNow && expire <= dateBound) {
							licenseExpiring = true;
						} else if (expire < maintenance && expire >= dateNow && expire <= dateBound) {
							licenseExpiring = true;
						} else if (expire < maintenance && maintenance >= dateNow && maintenance <= dateBound) {
							licenseExpiring = false;
						} else if (expire === maintenance) {
							licenseExpiring = null;
						}

						if (licenseExpiring === null) {
							obj.expiringType = "License and Maintenance";
							obj.expiringIn = expire;
							obj.expiringLeft = moment(expire).diff(dateNow, "days");
						} else if (licenseExpiring) {
							obj.expiringType = "License";
							obj.expiringIn = expire;
							obj.expiringLeft = moment(expire).diff(dateNow, "days");
						} else if (!licenseExpiring) {
							obj.expiringType = "Maintenance";
							obj.expiringIn = maintenance;
							obj.expiringLeft = moment(maintenance).diff(dateNow, "days");
						}

						return obj;
					});

					this.setState({ licenses: result });
					this.setState({ loading: false });
				} catch {
					this.props.setSnackBar(true, "error", "Error! Can not load licenses from database.");
				}
			}
		});
	};

	checkExpiringLicense = (expire, obj, dateNow, dateBound) => {
		if (expire >= dateNow && expire <= dateBound) {
			obj.expiringType = "License";
			obj.expiringIn = obj.expireDate;
			obj.expiringLeft = moment(obj.expireDate).diff(dateNow, "days");
		}
		return obj;
	};

	checkExpiringMaintenance = (maintenance, obj, dateNow, dateBound) => {
		if (maintenance >= dateNow && maintenance <= dateBound) {
			obj.expiringType = "Maintenance";
			obj.expiringIn = obj.maintenanceDate;
			obj.expiringLeft = moment(obj.maintenanceDate).diff(dateNow, "days");
		}
		return obj;
	};

	render() {
		return (
			<div>
				<Header />
				<CustomSideMenu
					sendView={
						<MaterialTable
							icons={tableIcons}
							style={{ marginBottom: 40 }}
							columns={[
								{ title: "License ID", field: "licenseId.licenseId", editable: "never" },
								{ title: "Expiration type", field: "expiringType", editable: "never" },
								{ title: "Days left", field: "expiringLeft", align: "center", editable: "never" },
								{ title: "Expiring in", field: "expiringIn", align: "center", editable: "never", defaultSort: "asc" },
								{ title: "Comment", field: "licenseId.comment", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{ title: "User", field: "licenseId.userId.email", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{
									title: "Classification",
									field: "licenseId.licenseeClassificationId.name",
									editable: "never",
									hidden: true,
									hiddenByColumnsButton: true,
								},
								{ title: "Version", field: "allowedVersion.version", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{ title: "Variant", field: "licenseId.licensedVariant.variant", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{ title: "Last action", field: "action", editable: "never", hidden: true, hiddenByColumnsButton: true },
							]}
							data={!this.state.loading && this.state.licenses}
							title="Expiring licenses"
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 40, 80],
								actionsColumnIndex: -1,
								columnsButton: true,
							}}
							onRowClick={(e, rowData) => this.openDialog(rowData.licenseId.licenseId, true)}
						/>
					}
				/>

				{this.props.dialogOpen && <CustomDialog loadDataToDialog={(e) => this.loadExpirationData()} />}
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		expiringLicensesHistory: state.licenseHistoryReducer.expiringLicensesHistory,
		dialogOpen: state.dialogReducer.dialogOpen,
		licenseId: state.dialogReducer.licenseId,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		getAllExpiringLicensesHistory: () => dispatch(getAllExpiringLicensesHistory()),
		setSnackBar: (open, variant, message) => dispatch(setSnackBar(open, variant, message)),
		setDialog: (dialogOpen, licenseId) => dispatch(setDialog(dialogOpen, licenseId)),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpirationManagementPage));
