import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className="footer-root">
                <CssBaseline />
                <footer className="footer">
                    <Container maxWidth="sm">
                        <Typography variant="body1">
                            © SupremITS, 2020
                        </Typography>
                    </Container>
                </footer>
            </div>
        );
    }
}

export default Footer;
