export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";
export const LOGOUT = "LOGOUT";

export const SET_LICENSE = "SET_LICENSE";
export const SET_LICENSES = "SET_LICENSES";
export const CREATE_LICENSE = "CREATE_LICENSE";
export const UPDATE_LICENSE = "UPDATE_LICENSE";

export const SET_LICENSE_VERSION = "SET_LICENSE_VERSION";
export const SET_LICENSE_VERSIONS = "SET_LICENSE_VERSIONS";
export const CREATE_LICENSE_VERSION = "CREATE_LICENSE_VERSION";
export const UPDATE_LICENSE_VERSION = "UPDATE_LICENSE_VERSION";
export const DELETE_LICENSE_VERSION = "DELETE_LICENSE_VERSION";

export const SET_LICENSE_VARIANT = "SET_LICENSE_VARIANT";
export const SET_LICENSE_VARIANTS = "SET_LICENSE_VARIANTS";
export const CREATE_LICENSE_VARIANT = "CREATE_LICENSE_VARIANT";
export const UPDATE_LICENSE_VARIANT = "UPDATE_LICENSE_VARIANT";
export const DELETE_LICENSE_VARIANT = "DELETE_LICENSE_VARIANT";

export const SET_LICENSEE_CLASSIFICATION = "SET_LICENSEE_CLASSIFICATION";
export const SET_LICENSEE_CLASSIFICATIONS = "SET_LICENSEE_CLASSIFICATIONS";
export const CREATE_LICENSEE_CLASSIFICATION = "CREATE_LICENSEE_CLASSIFICATION";
export const UPDATE_LICENSEE_CLASSIFICATION = "UPDATE_LICENSEE_CLASSIFICATION";
export const DELETE_LICENSEE_CLASSIFICATION = "DELETE_LICENSEE_CLASSIFICATION";

export const SET_LAST_LICENSE_HISTORY = "SET_LAST_LICENSE_HISTORY";
export const SET_LICENSE_HISTORY = "SET_LICENSE_HISTORY";
export const SET_LICENSES_HISTORY = "SET_LICENSES_HISTORY";
export const SET_EXPIRING_LICENSES_HISTORY = "SET_EXPIRING_LICENSES_HISTORY";
export const CREATE_LICENSE_HISTORY = "CREATE_LICENSE_HISTORY";

export const SET_HOST_HISTORY_ACTIVATIONS = "SET_HOST_HISTORY_ACTIVATIONS";

export const SET_SNACKBAR = "SET_SNACKBAR";

export const SET_DIALOG = "SET_DIALOG";