export const JWT_TOKEN = "authorization";
export const JWT_REFRESH = "refresh";
export const ROLE_ADMIN = "administrator";
export const ROLE_USER = "user";
/* Username field restrictions:
// (1) From  to 32 chars length;
// (2) Must contain atleast one uppercase letter;
// (3) Must contain atleast one number.
// (4) All special characters can be used
*/
export const USERNAME_REGEX = /^[a-zA-Z0-9_-]{4,16}$/;
/* Password field restrictions:
// (1) From 4 to 16 chars length;
// (2) Can contain letters, numbers, '-' and '_' characters
*/
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{5,32}$/;
/* Email field restrictions:
// (1) From 6 chars length;
// (2) Must contain '@' after atleast one char;
// (3) Must contain '.' before atleast one char and atleast 2 letters afterwards.
*/
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;