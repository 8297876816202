import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { setSnackBar } from "../../actions/SnackBarActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  alert: {
    marginBottom: '50px'
  },
}));

export default function CustomSnackBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackBarOpen = useSelector(state => state.snackBarReducer.open);
  const snackBarType = useSelector(state => state.snackBarReducer.variant);
  const snackBarMessage = useSelector(state => state.snackBarReducer.message);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackBar(false, snackBarType, snackBarMessage));
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          color={snackBarType}
          className={classes.alert}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};