import { SET_USER, SET_USERS, LOGOUT } from "../constants/ActionTypes";

const initialState = {
    user: {},
    users: [],
  };

  export default function userReducer(state = initialState, action) {
    if (action.type === SET_USER) {
      return Object.assign({}, state, {
        user: action.payload
      });
    }
    if (action.type === SET_USERS) {
      return Object.assign({}, state, {
        users: action.payload
      });
    }
    if (action.type === LOGOUT) {
      return Object.assign({}, state, {
        user: {}
      });
    }
    return state;
  };