import axios from "axios";
import {
	SET_LAST_LICENSE_HISTORY,
	SET_LICENSE_HISTORY,
	SET_LICENSES_HISTORY,
	SET_EXPIRING_LICENSES_HISTORY,
	CREATE_LICENSE_HISTORY,
	SET_HOST_HISTORY_ACTIVATIONS,
} from "../constants/ActionTypes";
import { JWT_TOKEN, JWT_REFRESH } from "../constants/Config";
import { setSnackBar } from "./SnackBarActions";
import { logout, refreshToken } from "./UserActions";

function setLastLicenseHistory(lastLicenseHistory) {
	return {
		type: SET_LAST_LICENSE_HISTORY,
		payload: lastLicenseHistory,
	};
}

function setLicenseHistory(licenseHistory) {
	return {
		type: SET_LICENSE_HISTORY,
		payload: licenseHistory,
	};
}

function setLicensesHistory(licensesHistory) {
	return {
		type: SET_LICENSES_HISTORY,
		payload: licensesHistory,
	};
}

function setExpiringLicensesHistory(expiringLicensesHistory) {
	return {
		type: SET_EXPIRING_LICENSES_HISTORY,
		payload: expiringLicensesHistory,
	};
}

function setLicenseHostHistoryActivations(hostHistoryActivations) {
	return {
		type: SET_HOST_HISTORY_ACTIVATIONS,
		payload: hostHistoryActivations,
	};
}

export function getAllLicensesHistory() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/history/all`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				var sortedByDESC = res.data.sort((a, b) => {
					return new Date(b.entryDate).getTime() - new Date(a.entryDate).getTime();
				});
				dispatch(setLicensesHistory(sortedByDESC));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}

export function getLicenseHistoryByLicenseId(licenseId) {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/history/` + licenseId, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				var sortedByDESC = res.data.sort((a, b) => {
					return new Date(b.entryDate).getTime() - new Date(a.entryDate).getTime();
				});
				dispatch(setLicenseHistory(sortedByDESC));
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function getLastLicenseHistoryByLicenseId(licenseId) {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/history/last/` + licenseId, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setLastLicenseHistory(res.data));
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function getAllExpiringLicensesHistory() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/history/ending`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setExpiringLicensesHistory(res.data));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}

export function createLicenseHistory(licenseHistory) {
	return function (dispatch) {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/licenses/history/create`, licenseHistory, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "New license history created successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getLicenseHistoryByLicenseId(licenseHistory.licenseId.licenseId));
				dispatch(getLastLicenseHistoryByLicenseId(licenseHistory.licenseId.licenseId));
				return {
					type: CREATE_LICENSE_HISTORY,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function getLicenseHostHistoryActivations(licenseId) {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/history/host/` + licenseId, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				var sortedByDESC = res.data.sort((a, b) => {
					return (
						new Date(b.hostHistoryActivationsId.hostHistId.hostHistoryId.entryDate).getTime() -
						new Date(a.hostHistoryActivationsId.hostHistId.hostHistoryId.entryDate).getTime()
					);
				});
				dispatch(setLicenseHostHistoryActivations(sortedByDESC));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}
