import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import userReducer from "../reducers/UserReducer";
import licenseReducer from "../reducers/LicenseReducer";
import licenseVariantReducer from "../reducers/LicenseVariantReducer";
import licenseVersionReducer from "../reducers/LicenseVersionReducer";
import licenseeClassificationReducer from "../reducers/LicenseeClassificationReducer";
import licenseHistoryReducer from "../reducers/LicenseHistoryReducer";
import snackBarReducer from "../reducers/SnackBarReducer";
import dialogReducer from "../reducers/DialogReducer";
import { LOGOUT } from "../constants/ActionTypes";

const appReducer = combineReducers({
	userReducer,
	licenseReducer,
	licenseHistoryReducer,
	licenseVariantReducer,
	licenseVersionReducer,
	licenseeClassificationReducer,
	snackBarReducer,
	dialogReducer,
});

const rootReducer = (state, action) => {
	if (action.type === LOGOUT) {
		localStorage.removeItem("localData");
		state = undefined;
	}

	return appReducer(state, action);
};

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
	const { logger } = require(`redux-logger`);

	middlewares.push(logger);
}

const LOCAL_STORAGE_NAME = "localData";

class PersistedStore {
	// Singleton property
	static DefaultStore = null;

	// Accessor to the default instance of this class
	static getDefaultStore() {
		if (PersistedStore.DefaultStore === null) {
			PersistedStore.DefaultStore = new PersistedStore();
		}
		return PersistedStore.DefaultStore;
	}

	// Redux store
	_store = null;

	// When class instance is used, initialize the store
	constructor() {
		this.initStore();
	}

	// Initialization of Redux Store
	initStore() {
		this._store = createStore(rootReducer, PersistedStore.loadState(), applyMiddleware(thunk, ...middlewares));
		this._store.subscribe(() => {
			PersistedStore.saveState(this._store.getState());
		});
	}

	// Getter to access the Redux store
	get store() {
		return this._store;
	}

	// Loading persisted state from localStorage
	static loadState() {
		try {
			let serializedState = localStorage.getItem(LOCAL_STORAGE_NAME);

			if (serializedState === null) {
				return PersistedStore.initialState();
			}

			return JSON.parse(serializedState);
		} catch (err) {
			return PersistedStore.initialState();
		}
	}

	// Saving persisted state to localStorage every time something
	// changes in the Redux Store (This happens because of the subscribe() in the initStore-method).
	static saveState(state) {
		try {
			let serializedState = JSON.stringify(state);
			localStorage.setItem(LOCAL_STORAGE_NAME, serializedState);
		} catch (err) {
			console.log(err);
		}
	}

	// Return whatever you want your initial state to be
	static initialState() {
		return {};
	}
}

export default PersistedStore;
