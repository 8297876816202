import axios from "axios";
import { SET_LICENSE_VERSIONS, CREATE_LICENSE_VERSION, UPDATE_LICENSE_VERSION, DELETE_LICENSE_VERSION } from "../constants/ActionTypes";
import { JWT_TOKEN, JWT_REFRESH } from "../constants/Config";
import { setSnackBar } from "./SnackBarActions";
import { logout, refreshToken } from "./UserActions";

function setLicenseVersions(licenseVersions) {
	return {
		type: SET_LICENSE_VERSIONS,
		payload: licenseVersions,
	};
}

export function getAllLicenseVersions() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/products/versions/all`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setLicenseVersions(res.data));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}

export function createLicenseVersion(licenseVersion) {
	return function (dispatch) {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/products/versions/create`, licenseVersion, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "New product version created successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseVersions());
				return {
					type: CREATE_LICENSE_VERSION,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function updateLicenseVersion(licenseVersion) {
	return function (dispatch) {
		return axios
			.patch(`${process.env.REACT_APP_API_URL}/products/versions/update/${licenseVersion.id}`, licenseVersion, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "Product version updated successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseVersions());
				return {
					type: UPDATE_LICENSE_VERSION,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function deleteLicenseVersion(licenseVersion) {
	return function (dispatch) {
		return axios
			.delete(`${process.env.REACT_APP_API_URL}/products/versions/delete/${licenseVersion.id}`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "Product version deleted successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseVersions());
				return {
					type: DELETE_LICENSE_VERSION,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}
