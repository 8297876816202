import React, { Component } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import './App.css';
import LoginPage from './views/Login/LoginPage';
import HomePage from './HomePage';
import UserManagementPage from './views/User/UserManagementPage';
import LicensesManagementPage from './views/License/LicenseManagementPage';
import ProductManagementPage from './views/License/ProductManagementPage';
import CustomSnackbar from './components/alerts/CustomSnackBar';

class App extends Component {
  
  render(){
    return(
      <Router>
      <div>
          <CustomSnackbar/>
          <Switch>
            <Route path={"/login"} component={LoginPage}></Route>
            <Route exact path={"/"} component={HomePage}></Route>
            <Route path={"/users"} component={UserManagementPage}></Route>
            <Route path={"/licenses"} component={LicensesManagementPage}></Route>
            <Route path={"/products"} component={ProductManagementPage}></Route>
            <Route component={HomePage}></Route>
          </Switch>
      </div>
    </Router>
    );
  }
}

export default App;