import React from 'react';
import Header from '../../components/sections/Header';
import Footer from '../../components/sections/Footer';
import { JWT_TOKEN, PASSWORD_REGEX, EMAIL_REGEX } from '../../constants/Config';
import { withRouter } from 'react-router-dom'
import { Button, CssBaseline, TextField, Paper, Backdrop, Typography, Container } from '@material-ui/core';
import { login } from "../../actions/UserActions";
import { connect } from "react-redux";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: '',
            password: '',
            passwordError: '',
            loading: true,
        };
    }

    componentDidMount() {
        if (sessionStorage.getItem(JWT_TOKEN)) {
            this.props.history.push("/");
        } else {
            this.setState({ loading: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (sessionStorage.getItem(JWT_TOKEN)) {
            this.props.history.push("/");
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.login(this.state.email, this.state.password);
    }

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    }

    handlePasswordChange = event => {
        this.setState({ password: event.target.value });
    }

    handleEmailValidation = event => {
        if (event.target.value.match(EMAIL_REGEX) || event.target.value.match("master")) {
            this.setState({ emailError: '' })
        } else {
            this.setState({ emailError: 'Error! Wrong email format.' })
        }
    }
    
    handlePasswordValidation = event => {
        if (event.target.value.match(PASSWORD_REGEX)) {
            this.setState({ passwordError: '' })
        } else {
            this.setState({ passwordError: 'Error! Wrong password format.' })
        }
    }

    render() {
        return (
            <div>
                <Header />
                {
                    !this.state.loading &&
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Backdrop className="backdrop" open={true}>
                            <Paper variant="outlined" className="paper">
                                <Typography component="h1" variant="h5">
                                    Please login
                                    </Typography>
                                <form className="form" onSubmit={this.handleSubmit}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={this.handleEmailChange}
                                        onBlur={this.handleEmailValidation}
                                        helperText={this.state.emailError}
                                        FormHelperTextProps={{ error: true }}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={this.handlePasswordChange}
                                        onBlur={this.handlePasswordValidation}
                                        helperText={this.state.passwordError}
                                        FormHelperTextProps={{ error: true }}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: '20px' }}
                                        className="submit"
                                    >
                                        Login
                                        </Button>
                                </form>
                            </Paper>
                        </Backdrop>
                    </Container>
                }
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    };
};

function mapDispatchToProps(dispatch) {
    return {
        login: (email, password) => dispatch(login(email, password)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));