import {
	SET_LAST_LICENSE_HISTORY,
	SET_LICENSE_HISTORY,
	SET_LICENSES_HISTORY,
	SET_EXPIRING_LICENSES_HISTORY,
	SET_HOST_HISTORY_ACTIVATIONS,
} from "../constants/ActionTypes";

const initialState = {
	lastLicenseHistory: {},
	licenseHistory: [],
	licensesHistory: [],
	expiringLicensesHistory: [],
	hostHistoryActivations: [],
};

export default function licenseHistoryReducer(state = initialState, action) {
	if (action.type === SET_LAST_LICENSE_HISTORY) {
		return Object.assign({}, state, {
			lastLicenseHistory: action.payload,
		});
	}
	if (action.type === SET_LICENSE_HISTORY) {
		return Object.assign({}, state, {
			licenseHistory: action.payload,
		});
	}
	if (action.type === SET_LICENSES_HISTORY) {
		return Object.assign({}, state, {
			licensesHistory: action.payload,
		});
	}
	if (action.type === SET_EXPIRING_LICENSES_HISTORY) {
		return Object.assign({}, state, {
			expiringLicensesHistory: action.payload,
		});
	}
	if (action.type === SET_HOST_HISTORY_ACTIVATIONS) {
		return Object.assign({}, state, {
			hostHistoryActivations: action.payload,
		});
	}
	return state;
}
