import React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, IconButton, ListItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CustomDropdown from "../menus/CustomDropdown";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
});

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onTitleClick = () => {
		this.props.history.push("/");
	};

	render() {
		const { classes } = this.props;
		return (
			<div className="root">
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar className="ToolBar">
						<IconButton className="menuButton" edge="start" color="inherit" aria-label="menu">
							<MenuIcon />
						</IconButton>
						<Typography className="title" variant="h6">
							<ListItem button component="a" onClick={this.onTitleClick}>
								{"Licsrv"}
							</ListItem>
						</Typography>
						{this.props.location.pathname !== "/login" ? (
							<CustomDropdown userEmail={this.props.user.email} userRole={this.props.user.role} />
						) : (
							"Guest"
						)}
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.user,
	};
};

function mapDispatchToProps(dispatch) {
	return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header)));
