import axios from "axios";
import { SET_USER, SET_USERS, CREATE_USER, LOGOUT } from "../constants/ActionTypes";
import { JWT_TOKEN, JWT_REFRESH } from "../constants/Config";
import { setSnackBar } from "./SnackBarActions";

function setUser(user) {
	return {
		type: SET_USER,
		payload: user,
	};
}

function setUsers(users) {
	return {
		type: SET_USERS,
		payload: users,
	};
}

export function logout() {
	sessionStorage.clear();
	localStorage.clear();
	return {
		type: LOGOUT,
	};
}

export function login(email, password) {
	return function (dispatch) {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/login`, {
				email,
				password,
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(getUserByToken());
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", "Incorrect credentials!"));
			});
	};
}

export function getUserByToken() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/users/authenticate`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setUser(res.data));
				dispatch(setSnackBar(true, "success", "Logged in as " + res.data.email));
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function getAllUsers() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/users/all`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setUsers(res.data));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}

export function createUser(user) {
	return function (dispatch) {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/users/create`, user, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "New user added successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllUsers());
				return {
					type: CREATE_USER,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function updateUser(user) {
	return function (dispatch) {
		return axios
			.patch(`${process.env.REACT_APP_API_URL}/users/update/${user.id}`, user, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "User updated successfully!"));
				dispatch(refreshToken(res.headers));
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function deleteUser(user) {
	return function (dispatch) {
		return axios
			.delete(`${process.env.REACT_APP_API_URL}/users/delete/${user.id}`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "User deleted successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllUsers());
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function refreshToken(headers) {
	return function (dispatch) {
		if (headers.authorization && headers.authorization !== sessionStorage.getItem(JWT_TOKEN)) {
			const proc = async () => {
				await sessionStorage.setItem(JWT_TOKEN, headers.authorization);
				await sessionStorage.setItem(JWT_REFRESH, headers.refresh);
			};
			proc().then();
		}
	};
}
