import React from "react";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import CustomSideMenu from "../../components/menus/CustomSideMenu";
import MaterialTable from "material-table";
import { Grid, TextField } from "@material-ui/core";
import { forwardRef } from "react";
import { getAllLicenseVariants, createLicenseVariant, updateLicenseVariant, deleteLicenseVariant } from "../../actions/LicenseVariantActions";
import { getAllLicenseVersions, createLicenseVersion, updateLicenseVersion, deleteLicenseVersion } from "../../actions/LicenseVersionActions";
import {
	getAllLicenseeClassifications,
	createLicenseeClassification,
	updateLicenseeClassification,
	deleteLicenseeClassification,
} from "../../actions/LicenseeClassificationActions";
import { JWT_TOKEN, ROLE_ADMIN } from "../../constants/Config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class ProductManagementPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
			loading: true,
		};
	}

	componentDidMount() {
		if (this.props.user.role !== ROLE_ADMIN) {
			this.props.history.push("/login");
		} else {
			const proc = async () => {
				await trackPromise(this.props.getAllLicenseVariants());
				await trackPromise(this.props.getAllLicenseVersions());
				await trackPromise(this.props.getAllLicenseeClassifications());
			};
			proc().then((res) => {
				if (!sessionStorage.getItem(JWT_TOKEN)) {
					this.props.history.push("/login");
				} else {
					this.setState({ loading: false });
				}
			});
		}
	}

	render() {
		return (
			<div>
				<Header />
				<CustomSideMenu
					sendView={
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<MaterialTable
									icons={tableIcons}
									columns={[{ title: "Version", field: "version" }]}
									data={!this.state.loading ? this.props.licenseVersions : this.state.products}
									title="Versions management"
									options={{
										pageSize: 3,
										pageSizeOptions: [3, 6, 12],
										actionsColumnIndex: -1,
										maxBodyHeight: 250,
										searchFieldStyle: {
											width: 250,
										},
									}}
									editable={{
										onRowAdd: (newData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.createLicenseVersion(newData);
												}, 1000);
											}),
										onRowUpdate: (newData, oldData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.updateLicenseVersion(newData);
												}, 1000);
											}),
										onRowDelete: (oldData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.deleteLicenseVersion(oldData);
												}, 1000);
											}),
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<MaterialTable
									icons={tableIcons}
									columns={[{ title: "Name", field: "name" }]}
									data={!this.state.loading ? this.props.licenseeClassifications : this.state.products}
									title="Classifications management"
									options={{
										pageSize: 3,
										pageSizeOptions: [3, 6, 12],
										actionsColumnIndex: -1,
										maxBodyHeight: 250,
										searchFieldStyle: {
											width: 250,
										},
									}}
									editable={{
										onRowAdd: (newData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.createLicenseeClassification(newData);
												}, 1000);
											}),
										onRowUpdate: (newData, oldData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.updateLicenseeClassification(newData);
												}, 1000);
											}),
										onRowDelete: (oldData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.deleteLicenseeClassification(oldData);
												}, 1000);
											}),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<MaterialTable
									icons={tableIcons}
									columns={[
										{ title: "Variant", field: "variant" },
										{ title: "Label", field: "label" },
										{
											title: "Comment",
											field: "comment",
											editComponent: (props) => {
												return (
													<TextField
														multiline
														onChange={(e) => props.onChange(e.target.value)}
														value={props.value}
														placeholder={props.columnDef.title}
													/>
												);
											},
										},
									]}
									data={!this.state.loading ? this.props.licenseVariants : this.state.products}
									title="Variants management"
									options={{
										pageSize: 3,
										pageSizeOptions: [3, 6, 12],
										actionsColumnIndex: -1,
										maxBodyHeight: 250,
										searchFieldStyle: {
											width: 250,
										},
									}}
									editable={{
										onRowAdd: (newData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.createLicenseVariant(newData);
												}, 1000);
											}),
										onRowUpdate: (newData, oldData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.updateLicenseVariant(newData);
												}, 1000);
											}),
										onRowDelete: (oldData) =>
											new Promise((resolve, reject) => {
												setTimeout(() => {
													resolve();
													this.props.deleteLicenseVariant(oldData);
												}, 1000);
											}),
									}}
								/>
							</Grid>
						</Grid>
					}
				/>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.user,
		licenseVariants: state.licenseVariantReducer.licenseVariants,
		licenseVersions: state.licenseVersionReducer.licenseVersions,
		licenseeClassifications: state.licenseeClassificationReducer.licenseeClassifications,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		getAllLicenseVariants: () => dispatch(getAllLicenseVariants()),
		createLicenseVariant: (licenseVariant) => dispatch(createLicenseVariant(licenseVariant)),
		updateLicenseVariant: (licenseVariant) => dispatch(updateLicenseVariant(licenseVariant)),
		deleteLicenseVariant: (licenseVariant) => dispatch(deleteLicenseVariant(licenseVariant)),
		getAllLicenseVersions: () => dispatch(getAllLicenseVersions()),
		createLicenseVersion: (licenseVersion) => dispatch(createLicenseVersion(licenseVersion)),
		updateLicenseVersion: (licenseVersion) => dispatch(updateLicenseVersion(licenseVersion)),
		deleteLicenseVersion: (licenseVersion) => dispatch(deleteLicenseVersion(licenseVersion)),
		getAllLicenseeClassifications: () => dispatch(getAllLicenseeClassifications()),
		createLicenseeClassification: (licenseeClassification) => dispatch(createLicenseeClassification(licenseeClassification)),
		updateLicenseeClassification: (licenseeClassification) => dispatch(updateLicenseeClassification(licenseeClassification)),
		deleteLicenseeClassification: (licenseeClassification) => dispatch(deleteLicenseeClassification(licenseeClassification)),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductManagementPage));
