import React from "react";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import CustomSideMenu from "../../components/menus/CustomSideMenu";
import CustomDialog from "./../../components/menus/CustomDialog";
import MaterialTable from "material-table";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Select, FormControl, Button, Typography, Dialog, DialogTitle, TextField, FormHelperText, Switch } from "@material-ui/core";
import { forwardRef } from "react";
import { getAllLicenses, getLicenseById, createLicense, updateLicense } from "../../actions/LicenseActions";
import { getAllLicenseVariants } from "../../actions/LicenseVariantActions";
import { getAllLicenseVersions } from "../../actions/LicenseVersionActions";
import { getAllLicenseeClassifications } from "../../actions/LicenseeClassificationActions";
import {
	getLicenseHistoryByLicenseId,
	getLastLicenseHistoryByLicenseId,
	getAllLicensesHistory,
	createLicenseHistory,
} from "../../actions/LicenseHistoryActions";
import { setSnackBar } from "../../actions/SnackBarActions";
import { setDialog } from "../../actions/DialogActions";
import { JWT_TOKEN, ROLE_ADMIN } from "../../constants/Config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import "moment/locale/lt";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = (theme) => ({
	root: {
		display: "flex",
	},
	backdrop: {
		color: "#fff",
		position: "absolute",
		marginTop: 70,
		marginBottom: 40,
		left: 260,
		right: 20,
		bottom: 60,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	button: {
		margin: 0,
		top: 20,
		right: 20,
		bottom: "auto",
		left: "auto",
		position: "absolute",
	},
	contentSpacing: {
		padding: "30px",
	},
	error: {
		"&.MuiFormHelperText-root.Mui-error": {
			color: theme.palette.common.white,
		},
	},
});

class LicenseManagementPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addLicense: false,

			serialNum: "0",
			version: "",
			versionError: "",
			variant: "",
			variantError: "",
			classification: "",
			classificationError: "",
			maintenanceDate: null,
			expirationDate: null,
			comment: "",

			licenses: [],
			licenseeClassificationId: "",
			licenseFloating: false,
			currentDate: null,
		};
	}

	componentDidMount() {
		if (!sessionStorage.getItem(JWT_TOKEN)) {
			this.props.history.push("/login");
		} else {
			this.loadLicensesData();
		}
	}

	changeAddLicenseState = (event, value) => {
		const proc = async () => {
			await trackPromise(this.props.getAllLicenseVariants());
			await trackPromise(this.props.getAllLicenseVersions());
			await trackPromise(this.props.getAllLicenseeClassifications());
		};
		proc().then((res) => {
			this.setState({ addLicense: value });
		});
	};

	openDialog = (rowData, value) => {
		this.props.setDialog(value, rowData);
	};

	loadLicensesData = () => {
		const proc = async () => {
			await trackPromise(this.props.getAllLicenses());
			await trackPromise(this.props.getAllLicensesHistory());
		};
		proc().then((res) => {
			if (!sessionStorage.getItem(JWT_TOKEN)) {
				this.props.history.push("/login");
			} else {
				try {
					const uniqueLicenses = this.props.licenses.map((license) =>
						this.props.licensesHistory
							.filter((data) => data.licenseId.licenseId === license.licenseId)
							.sort((a, b) => b.licHistId - a.licHistId)
							.find((res) => res.licenseId.licenseId === license.licenseId)
					);
					this.setState({ licenses: uniqueLicenses });
					this.setState({ loading: false });
				} catch {
					this.props.setSnackBar(true, "error", "Error! Can not load licenses from database.");
				}
			}
		});
	};

	changeAddVersion = (event) => {
		this.setState({ version: event.target.value });
	};

	handleAddVersionValidation = (event) => {
		if (event.target.value !== "") {
			this.setState({ versionError: "" });
		} else {
			this.setState({ versionError: "Error! This field is required" });
		}
	};

	changeAddVariant = (event) => {
		this.setState({ variant: event.target.value });
	};

	handleAddVariantValidation = (event) => {
		if (event.target.value !== "") {
			this.setState({ variantError: "" });
		} else {
			this.setState({ variantError: "Error! This field is required" });
		}
	};

	changeAddClassification = (event) => {
		this.setState({ classification: event.target.value });
	};

	handleAddClassificationValidation = (event) => {
		if (event.target.value !== "") {
			this.setState({ classificationError: "" });
		} else {
			this.setState({ classificationError: "Error! This field is required" });
		}
	};

	changeAddMaintenanceDate = (date) => {
		this.setState({ maintenanceDate: moment(date).format("YYYY-MM-DD") });
	};

	changeAddExpirationDate = (date) => {
		this.setState({ expirationDate: moment(date).format("YYYY-MM-DD") });
	};
	changeAddComment = (event) => {
		this.setState({ comment: event.target.value });
	};

	handleSwitchClick = (event) => {
		this.setState({ expirationDate: event.target.checked ? this.state.currentDate : null });
		this.setState({ licenseFloating: event.target.checked });
	};

	handleCreateLicense = (event) => {
		if (this.state.serialNum !== "" && this.state.variant !== "" && this.state.classification !== "" && this.state.version !== "") {
			const license = {
				serialNum: this.state.serialNum,
				licensedVariant: { id: this.state.variant },
				licenseeClassificationId: { licenseeClassificationId: this.state.classification },
				comment: this.state.comment,
				initialHostings: false,
				requiresDongle: false,
				email: true,
				userId: { id: this.props.user.id },
			};
			const licenseHistory = {
				allowedVersion: { id: this.state.version },
				maintenanceDate: this.state.maintenanceDate,
				expireDate: this.state.expirationDate,
				userId: { id: this.props.user.id },
			};
			const proc = async () => {
				await trackPromise(this.props.createLicense(license, licenseHistory));
			};
			proc().then((res) => {
				this.setState({ addLicense: false });
				this.loadLicensesData();
				this.openDialog(this.props.license.licenseId, true);
			});
		} else {
			this.props.setSnackBar(true, "error", "Error! Required fields must be filled");
		}
	};

	render() {
		const { classes } = this.props;
		return (
			<div>
				<Header />
				<CustomSideMenu
					sendView={
						<MaterialTable
							icons={tableIcons}
							style={{ marginBottom: 40 }}
							columns={[
								{ title: "License ID", field: "licenseId.licenseId", editable: "never" },
								{ title: "Maintenance date", field: "maintenanceDate", editable: "never" },
								{ title: "Expiration date", field: "expireDate", editable: "never" },
								{ title: "Comment", field: "licenseId.comment", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{ title: "User", field: "licenseId.userId.email", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{
									title: "Classification",
									field: "licenseId.licenseeClassificationId.name",
									editable: "never",
									hidden: true,
									hiddenByColumnsButton: true,
								},
								{ title: "Version", field: "allowedVersion.version", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{ title: "Variant", field: "licenseId.licensedVariant.variant", editable: "never", hidden: true, hiddenByColumnsButton: true },
								{ title: "Last action", field: "action", editable: "never", hidden: true, hiddenByColumnsButton: true },
							]}
							data={this.state.licenses}
							title="Licenses management panel"
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 40, 80],
								actionsColumnIndex: -1,
								columnsButton: true,
							}}
							onRowClick={(e, rowData) => this.openDialog(rowData.licenseId.licenseId, true)}
							actions={[
								{
									hidden: this.props.user.role === ROLE_ADMIN ? false : true,
									icon: tableIcons.Add,
									tooltip: "Create new license",
									isFreeAction: true,
									onClick: (e, rowData) => {
										this.changeAddLicenseState(e, true);
									},
								},
							]}
						/>
					}
				/>

				{this.state.addLicense && (
					<Dialog aria-labelledby="addLicenseDialog" open={this.state.addLicense} className={classes.backdrop} fullWidth maxWidth="sm">
						<DialogTitle id="addLicenseDialogTitle">Create new license</DialogTitle>
						<Fab size="small" className={classes.button} aria-label="add" onClick={(e) => this.changeAddLicenseState(false)}>
							<CloseIcon />
						</Fab>
						<Grid container className={classes.contentSpacing} justify="center">
							<FormControl
								size="small"
								margin="normal"
								fullWidth
								variant="outlined"
								onChange={this.handleAddVersionValidation}
								onBlur={this.handleAddVersionValidation}
							>
								<Select native={true} onChange={(e) => this.changeAddVersion(e)}>
									<option aria-label="None" value="">
										-- Select version * --
									</option>
									{this.props.licenseVersions &&
										this.props.licenseVersions.map((version) => (
											<option value={version.id} key={version.version}>
												{version.version}
											</option>
										))}
								</Select>
								{this.state.versionError !== "" && <FormHelperText error={true}>{this.state.versionError}</FormHelperText>}
							</FormControl>
							<FormControl
								size="small"
								margin="normal"
								fullWidth
								variant="outlined"
								onChange={this.handleAddVariantValidation}
								onBlur={this.handleAddVariantValidation}
							>
								<Select native={true} onChange={(e) => this.changeAddVariant(e)}>
									<option aria-label="None" value="">
										-- Select variant * --
									</option>
									{this.props.licenseVariants &&
										this.props.licenseVariants.map((variant) => (
											<option value={variant.id} key={variant.variant}>
												{variant.variant}
											</option>
										))}
								</Select>
								{this.state.variantError !== "" && <FormHelperText error={true}>{this.state.variantError}</FormHelperText>}
							</FormControl>
							<FormControl
								size="small"
								margin="normal"
								fullWidth
								variant="outlined"
								onChange={this.handleAddClassificationValidation}
								onBlur={this.handleAddClassificationValidation}
							>
								<Select native={true} onChange={(e) => this.changeAddClassification(e)}>
									<option aria-label="None" value="">
										-- Select classification * --
									</option>
									{this.props.licenseeClassifications &&
										this.props.licenseeClassifications.map((classification) => (
											<option value={classification.licenseeClassificationId} key={classification.name}>
												{classification.name}
											</option>
										))}
								</Select>
								{this.state.classificationError !== "" && <FormHelperText error={true}>{this.state.classificationError}</FormHelperText>}
							</FormControl>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									margin="normal"
									id="addMaitenanceDatePicker"
									format="yyyy-MM-dd"
									minDate={this.state.currentDate}
									minDateMessage="Error! This date has already passed"
									label="Maitenance date"
									fullWidth
									value={this.state.maintenanceDate}
									onChange={this.changeAddMaintenanceDate}
									KeyboardButtonProps={{
										"aria-label": "Maitenance date",
									}}
								/>
							</MuiPickersUtilsProvider>
							<FormControl>
								<Typography component="div">
									<Grid component="label" container alignItems="center" spacing={1}>
										<Grid item>Unlimited</Grid>
										<Grid item>
											<Switch
												checked={this.state.licenseFloating}
												onChange={this.handleSwitchClick}
												color="default"
												inputProps={{ "aria-label": "Choose license type" }}
											/>
										</Grid>
										<Grid item>Floating / Limited</Grid>
									</Grid>
								</Typography>
							</FormControl>
							{this.state.licenseFloating && (
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										margin="normal"
										id="addExpirationDatePicker"
										format="yyyy-MM-dd"
										minDate={this.state.currentDate}
										minDateMessage="Error! This date has already passed"
										label="Expiration date"
										fullWidth
										value={this.state.expirationDate}
										onChange={this.changeAddExpirationDate}
										KeyboardButtonProps={{
											"aria-label": "Expiration date",
										}}
									/>
								</MuiPickersUtilsProvider>
							)}
							<TextField
								id="commentField"
								label="Comment"
								margin="normal"
								multiline
								fullWidth
								rows={4}
								variant="outlined"
								onChange={this.changeAddComment}
							/>
							<Grid container justify="center">
								<Button type="submit" variant="contained" color="primary" margin="normal" className="submit" onClick={this.handleCreateLicense}>
									Create
								</Button>
							</Grid>
						</Grid>
					</Dialog>
				)}

				{this.props.dialogOpen && <CustomDialog loadDataToDialog={(e) => this.loadLicensesData()} />}
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.user,
		license: state.licenseReducer.license,
		licenses: state.licenseReducer.licenses,
		licenseVariants: state.licenseVariantReducer.licenseVariants,
		licenseVersions: state.licenseVersionReducer.licenseVersions,
		licenseeClassifications: state.licenseeClassificationReducer.licenseeClassifications,
		licensesHistory: state.licenseHistoryReducer.licensesHistory,
		licenseHistory: state.licenseHistoryReducer.licenseHistory,
		lastLicenseHistory: state.licenseHistoryReducer.lastLicenseHistory,
		dialogOpen: state.dialogReducer.dialogOpen,
		licenseId: state.dialogReducer.licenseId,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		getAllLicenses: () => dispatch(getAllLicenses()),
		getAllLicenseVariants: () => dispatch(getAllLicenseVariants()),
		getAllLicenseVersions: () => dispatch(getAllLicenseVersions()),
		getAllLicenseeClassifications: () => dispatch(getAllLicenseeClassifications()),
		getAllLicensesHistory: () => dispatch(getAllLicensesHistory()),
		getLicenseHistoryByLicenseId: (license) => dispatch(getLicenseHistoryByLicenseId(license)),
		getLastLicenseHistoryByLicenseId: (license) => dispatch(getLastLicenseHistoryByLicenseId(license)),
		getLicenseById: (license) => dispatch(getLicenseById(license)),
		createLicense: (license, licenseHistory) => dispatch(createLicense(license, licenseHistory)),
		updateLicense: (licenseId, license) => dispatch(updateLicense(licenseId, license)),
		createLicenseHistory: (licenseHistory) => dispatch(createLicenseHistory(licenseHistory)),
		setSnackBar: (open, variant, message) => dispatch(setSnackBar(open, variant, message)),
		setDialog: (dialogOpen, licenseId) => dispatch(setDialog(dialogOpen, licenseId)),
	};
}

LicenseManagementPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LicenseManagementPage)));
