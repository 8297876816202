import React from "react";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import {
	Paper,
	Grid,
	Select,
	FormControl,
	Button,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Table,
	TableHead,
	Dialog,
	DialogTitle,
	TextField,
	TablePagination,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getLicenseById, updateLicense } from "../../actions/LicenseActions";
import { getAllLicenseVariants } from "../../actions/LicenseVariantActions";
import { getAllLicenseVersions } from "../../actions/LicenseVersionActions";
import { getAllLicenseeClassifications } from "../../actions/LicenseeClassificationActions";
import {
	getLicenseHistoryByLicenseId,
	getLastLicenseHistoryByLicenseId,
	getAllExpiringLicensesHistory,
	createLicenseHistory,
	getLicenseHostHistoryActivations,
} from "../../actions/LicenseHistoryActions";
import { setSnackBar } from "../../actions/SnackBarActions";
import { setDialog } from "../../actions/DialogActions";
import { JWT_TOKEN, ROLE_ADMIN } from "../../constants/Config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TablePaginationActions from "../../components/tables/TablePaginationActions";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SyncIcon from "@material-ui/icons/Sync";

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	SyncIcon: forwardRef((props, ref) => <SyncIcon {...props} ref={ref} />),
};

const styles = (theme) => ({
	root: {
		display: "flex",
	},
	backdrop: {
		color: "#fff",
		position: "absolute",
		marginTop: 70,
		marginBottom: 40,
		left: 260,
		right: 20,
		bottom: 60,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	button: {
		margin: 0,
		top: 20,
		right: 20,
		bottom: "auto",
		left: "auto",
		position: "absolute",
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "33.33%",
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	contentSpacing: {
		padding: "30px",
	},
	headerSpacing: {
		paddingBottom: "20px",
	},
	buttonSpacing: {
		marginTop: "20px",
	},
	spacingRight: {
		marginRight: 10,
	},
	error: {
		"&.MuiFormHelperText-root.Mui-error": {
			color: theme.palette.common.white,
		},
	},
});

class CustomDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			expandedRight: "rightPanel1",
			loading: true,
			smallComponenLoading: false,

			version: "",
			variant: "",
			classification: "",
			maintenanceDate: null,
			comment: "",

			licenses: [],
			licensedVariantId: "",
			allowedVersionId: "",
			allowedVersionName: "",
			licenseVersionComment: "",
			licenseeClassificationId: "",
			maintenance: "",
			maintenanceComment: "",
			expiration: "",
			expirationComment: "",

			licenseUpdated: false,
			rowsPerPage: 6,
			page: 0,
			currentDate: null,
		};
	}

	componentDidMount() {
		if (!sessionStorage.getItem(JWT_TOKEN)) {
			this.props.history.push("/login");
		} else {
			if (this.props.dialogOpen && this.props.licenseId) {
				const proc = async () => {
					await trackPromise(this.props.getLicenseById(this.props.licenseId));
					await trackPromise(this.props.getAllLicenseVariants());
					await trackPromise(this.props.getAllLicenseVersions());
					await trackPromise(this.props.getAllLicenseeClassifications());
					await trackPromise(this.props.getLicenseHistoryByLicenseId(this.props.licenseId));
					await trackPromise(this.props.getLastLicenseHistoryByLicenseId(this.props.licenseId));
					await trackPromise(this.props.getLicenseHostHistoryActivations(this.props.licenseId));
				};
				proc().then((res) => {
					try {
						if (this.props.lastLicenseHistory.allowedVersion === null) {
							this.setState({
								allowedVersionId: this.props.lastLicenseHistory.allowedVersion,
							});
							this.setState({
								allowedVersionName: this.props.lastLicenseHistory.allowedVersion,
							});
						} else {
							this.setState({
								allowedVersionId: this.props.lastLicenseHistory.allowedVersion.id,
							});
							this.setState({
								allowedVersionName: this.props.lastLicenseHistory.allowedVersion.version,
							});
						}
						this.setState({
							licensedVariantId: this.props.lastLicenseHistory.licenseId.licensedVariant.id,
						});
						this.setState({
							licenseeClassificationId: this.props.lastLicenseHistory.licenseId.licenseeClassificationId.licenseeClassificationId,
						});
						this.setState({
							maintenance: this.props.lastLicenseHistory.maintenanceDate,
						});
						this.setState({
							expiration: this.props.lastLicenseHistory.expireDate,
						});

						this.setState({
							currentDate: moment().add(1, "days").format("YYYY-MM-DD"),
						});
						this.setState({
							maintenanceDate: moment().add(1, "days").format("YYYY-MM-DD"),
						});
						this.setState({ loading: false });
					} catch {
						this.props.setSnackBar(true, "error", "Error! Can not load license history.");
					}
				});
			}
		}
	}

	closeBackdrop = () => {
		if (this.state.licenseUpdated) {
			this.props.loadDataToDialog();
			this.setState({ licenseUpdated: false });
		}
		this.setState({ page: 0 });
		this.props.setDialog(false, null);
	};

	handleAccordionChange = (panel) => (event, expanded) => {
		if (!expanded) {
			this.setState({ expanded: false });
		} else {
			this.setState({ expanded: panel });
		}
	};

	handleRightAccordionChange = (panel) => (event, expandedRight) => {
		if (!expandedRight) {
			this.setState({ expandedRight: false });
		} else {
			this.setState({ expandedRight: panel });
		}
	};

	changeLicenseVariant = (event) => {
		this.setState({ licensedVariantId: event.target.value });
	};

	changeLicenseVersion = (event) => {
		this.setState({ allowedVersionId: event.target.value });
	};

	changeLicenseVersionComment = (event) => {
		this.setState({ licenseVersionComment: event.target.value });
	};

	changeLicenseeClassification = (event) => {
		this.setState({ licenseeClassificationId: event.target.value });
	};

	changeMaintenanceDate = (date) => {
		this.setState({ maintenance: moment(date).format("YYYY-MM-DD") });
	};

	changeMaintenanceComment = (event) => {
		this.setState({ maintenanceComment: event.target.value });
	};

	changeExpirationDate = (date) => {
		this.setState({ expiration: moment(date).format("YYYY-MM-DD") });
	};

	changeExpirationComment = (event) => {
		this.setState({ expirationComment: event.target.value });
	};

	handleLicenseVariantApply = () => {
		if (this.state.licensedVariantId !== this.props.license.licensedVariant.id) {
			const license = {
				licensedVariant: { id: this.state.licensedVariantId },
				licenseeClassificationId: {
					licenseeClassificationId: this.props.license.licenseeClassificationId.licenseeClassificationId,
				},
			};
			this.props.updateLicense(this.props.license.licenseId, license);
			this.setState({ licenseUpdated: true });
		} else {
			this.props.setSnackBar(true, "error", "Error! This product variant is already set");
		}
	};

	handleLicenseVersionApply = () => {
		if (this.props.lastLicenseHistory.allowedVersion != null && this.state.allowedVersionId !== this.props.lastLicenseHistory.allowedVersion.id) {
			const license = {
				allowedVersion: { id: this.state.allowedVersionId },
				maintenanceDate: this.props.lastLicenseHistory.maintenanceDate,
				expireDate: this.props.lastLicenseHistory.expireDate,
				licenseId: { licenseId: this.props.license.licenseId },
				action: "set-version",
				userId: { id: this.props.user.id },
				comment: this.state.licenseVersionComment,
			};
			this.props.createLicenseHistory(license);
			this.setState({ licenseUpdated: true });
		} else {
			this.props.setSnackBar(true, "error", "Error! This product version is already set");
		}
	};

	handleLicenseeClassificationApply = () => {
		if (this.state.licenseeClassificationId !== this.props.license.licenseeClassificationId.licenseeClassificationId) {
			const license = {
				licensedVariant: { id: this.props.license.licensedVariant.id },
				licenseeClassificationId: {
					licenseeClassificationId: this.state.licenseeClassificationId,
				},
			};
			this.props.updateLicense(this.props.license.licenseId, license);
			this.setState({ licenseUpdated: true });
		} else {
			this.props.setSnackBar(true, "error", "Error! This licensee classification is already set");
		}
	};

	handleMaintenanceDateApply = () => {
		if (this.state.maintenance !== this.props.lastLicenseHistory.maintenanceDate) {
			if (this.state.maintenance >= this.state.currentDate && this.state.maintenance !== "") {
				const license = {
					allowedVersion: {
						id: this.props.lastLicenseHistory.allowedVersion.id,
					},
					maintenanceDate: this.state.maintenance,
					expireDate: this.props.lastLicenseHistory.expireDate,
					licenseId: { licenseId: this.props.license.licenseId },
					action: "set-maintenance",
					userId: { id: this.props.user.id },
					comment: this.state.maintenanceComment,
				};
				this.props.createLicenseHistory(license);
				this.setState({ licenseUpdated: true });
			} else {
				this.props.setSnackBar(true, "error", "Error! This date has already passed");
			}
		} else {
			this.props.setSnackBar(true, "error", "Error! This maintenance date is already set");
		}
	};

	handleExpirationDateApply = () => {
		if (this.state.expiration !== this.props.lastLicenseHistory.expireDate) {
			if (this.state.expiration >= this.state.currentDate && this.state.expiration !== "") {
				const license = {
					allowedVersion: {
						id: this.props.lastLicenseHistory.allowedVersion.id,
					},
					maintenanceDate: this.props.lastLicenseHistory.maintenanceDate,
					expireDate: this.state.expiration,
					licenseId: { licenseId: this.props.license.licenseId },
					action: "set-expiration",
					userId: { id: this.props.user.id },
					comment: this.state.expirationComment,
				};
				this.props.createLicenseHistory(license);
				this.setState({ licenseUpdated: true });
			} else {
				this.props.setSnackBar(true, "error", "Error! This date has already passed");
			}
		} else {
			this.props.setSnackBar(true, "error", "Error! This expiration date is already set");
		}
	};

	handleBlock = () => {
		const license = {
			allowedVersion: { id: this.props.lastLicenseHistory.allowedVersion.id },
			maintenanceDate: this.props.lastLicenseHistory.maintenanceDate,
			expireDate: this.props.lastLicenseHistory.expireDate,
			licenseId: { licenseId: this.props.license.licenseId },
			action: "block-license",
			userId: { id: this.props.user.id },
			comment: "",
		};
		this.props.createLicenseHistory(license);
		this.setState({ licenseUpdated: true });
	};

	handleUnblock = () => {
		const license = {
			allowedVersion: { id: this.props.lastLicenseHistory.allowedVersion.id },
			maintenanceDate: this.props.lastLicenseHistory.maintenanceDate,
			expireDate: this.props.lastLicenseHistory.expireDate,
			licenseId: { licenseId: this.props.license.licenseId },
			action: "unblock-license",
			userId: { id: this.props.user.id },
			comment: "",
		};
		this.props.createLicenseHistory(license);
		this.setState({ licenseUpdated: true });
	};

	handleChangePage = (event, newPage) => {
		this.setState({ page: newPage });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: +event.target.value });
		this.setState({ page: 0 });
	};

	handleDataRefresh = (event) => {
		const proc = async () => {
			this.setState({ smallComponenLoading: true });
			await trackPromise(this.props.getLicenseHostHistoryActivations(this.props.licenseId));
		};
		proc().then((res) => {
			this.setState({ smallComponenLoading: false });
		});
	};

	render() {
		const { classes } = this.props;
		return (
			!this.state.loading && (
				<Dialog aria-labelledby="viewLicenseDialog" open={this.props.dialogOpen} className={classes.backdrop} fullWidth fullScreen maxWidth="xl">
					<DialogTitle id="viewLicenseDialogTitle">License ID: {this.props.license && this.props.license.licenseId}</DialogTitle>
					<Fab size="small" className={classes.button} aria-label="add" onClick={(e) => this.closeBackdrop()}>
						<CloseIcon />
					</Fab>
					<Grid container style={{ overflowY: "hidden" }}>
						<Grid item xs={12} md={6}>
							<Grid item xs={12} className={classes.contentSpacing}>
								<Paper className={classes.paper}>
									<Grid container spacing={0}>
										<Grid item xs={12} className={classes.headerSpacing}>
											Created by: {this.props.license && this.props.license.userId.username}, on:{" "}
											{this.props.license && moment(this.props.license.entryDate).format("YYYY-MM-DD HH:mm:ss")}
										</Grid>
									</Grid>
									<Accordion
										expanded={this.state.expanded === "panel6"}
										onChange={this.handleAccordionChange("panel6")}
										disabled={this.props.lastLicenseHistory.action === "block-license" ? true : false}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6bh-header">
											<Typography className={classes.heading}>Comment</Typography>
											<Typography className={classes.secondaryHeading}>
												{this.props.license.comment !== null && this.props.license.comment.length > 25
													? this.props.license.comment.substring(0, 25) + "..."
													: this.props.license.comment !== null
													? this.props.license.comment
													: "Not set"}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography variant="body1" gutterBottom>
												{this.props.license.comment}
											</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={this.state.expanded === "panel1"}
										onChange={this.handleAccordionChange("panel1")}
										disabled={this.props.lastLicenseHistory.action === "block-license" ? true : false}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
											<Typography className={classes.heading}>Product version</Typography>
											<Typography className={classes.secondaryHeading}>
												{this.props.lastLicenseHistory && this.props.lastLicenseHistory.allowedVersion === null
													? "Undefined"
													: this.props.lastLicenseHistory.allowedVersion.version}
											</Typography>
										</AccordionSummary>
										{this.props.user.role === ROLE_ADMIN && (
											<AccordionDetails>
												<Grid container justify="center" style={{ display: "flex", alignItems: "center" }}>
													<Grid item xs={12} md={5}>
														<FormControl size="small" variant="outlined" fullWidth>
															<Select
																native={true}
																value={this.state.allowedVersionId || 1}
																onChange={(e) => this.changeLicenseVersion(e)}
															>
																{this.props.licenseVersions &&
																	this.props.licenseVersions.map((version) => (
																		<option value={version.id} key={version.version}>
																			{version.version}
																		</option>
																	))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={12} md={5}>
														<TextField
															id="licenseVersionCommentField"
															label="Comment"
															size="small"
															variant="outlined"
															onChange={this.changeLicenseVersionComment}
														/>
													</Grid>
													<Grid item xs={12} md={2}>
														<Button color="primary" variant="outlined" onClick={this.handleLicenseVersionApply}>
															Apply
														</Button>
													</Grid>
												</Grid>
											</AccordionDetails>
										)}
									</Accordion>
									<Accordion
										expanded={this.state.expanded === "panel2"}
										onChange={this.handleAccordionChange("panel2")}
										disabled={this.props.lastLicenseHistory.action === "block-license" ? true : false}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
											<Typography className={classes.heading}>Product variant</Typography>
											<Typography className={classes.secondaryHeading}>
												{this.props.license && this.props.license.licensedVariant.variant}
											</Typography>
										</AccordionSummary>
										{this.props.user.role === ROLE_ADMIN && (
											<AccordionDetails>
												<Grid container justify="center">
													<FormControl size="small" variant="outlined" className={classes.spacingRight}>
														<Select
															native={true}
															value={this.state.licensedVariantId || 1}
															onChange={(e) => this.changeLicenseVariant(e)}
														>
															{this.props.licenseVariants &&
																this.props.licenseVariants.map((variant) => (
																	<option value={variant.id} key={variant.variant}>
																		{variant.variant}
																	</option>
																))}
														</Select>
													</FormControl>
													<Button color="primary" variant="outlined" onClick={this.handleLicenseVariantApply}>
														Apply
													</Button>
												</Grid>
											</AccordionDetails>
										)}
									</Accordion>
									<Accordion
										expanded={this.state.expanded === "panel3"}
										onChange={this.handleAccordionChange("panel3")}
										disabled={this.props.lastLicenseHistory.action === "block-license" ? true : false}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
											<Typography className={classes.heading}>Licensee classification</Typography>
											<Typography className={classes.secondaryHeading}>
												{this.props.license && this.props.license.licenseeClassificationId.name}
											</Typography>
										</AccordionSummary>
										{this.props.user.role === ROLE_ADMIN && (
											<AccordionDetails>
												<Grid container justify="center">
													<FormControl size="small" variant="outlined" className={classes.spacingRight}>
														<Select
															native={true}
															value={this.state.licenseeClassificationId || 6}
															onChange={(e) => this.changeLicenseeClassification(e)}
														>
															{this.props.licenseeClassifications &&
																this.props.licenseeClassifications.map((classification) => (
																	<option value={classification.licenseeClassificationId} key={classification.name}>
																		{classification.name}
																	</option>
																))}
														</Select>
													</FormControl>
													<Button color="primary" variant="outlined" onClick={this.handleLicenseeClassificationApply}>
														Apply
													</Button>
												</Grid>
											</AccordionDetails>
										)}
									</Accordion>
									<Accordion
										expanded={this.state.expanded === "panel4"}
										onChange={this.handleAccordionChange("panel4")}
										disabled={this.props.lastLicenseHistory.action === "block-license" ? true : false}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
											<Typography className={classes.heading}>Maintenance expiration date</Typography>
											<Typography className={classes.secondaryHeading}>
												{this.props.lastLicenseHistory.maintenanceDate != null
													? this.props.lastLicenseHistory.maintenanceDate
													: "Not set"}
											</Typography>
										</AccordionSummary>
										{this.props.user.role === ROLE_ADMIN && (
											<AccordionDetails>
												<Grid container justify="center" style={{ display: "flex", alignItems: "center" }}>
													<Grid item xs={12} md={5}>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															<KeyboardDatePicker
																margin="normal"
																id="maintenanceDatePicker"
																format="yyyy-MM-dd"
																minDate={this.state.currentDate}
																minDateMessage="Error! This date has already passed"
																value={
																	this.state.maintenance >= this.state.currentDate
																		? this.state.maintenance
																		: this.state.currentDate
																}
																onChange={this.changeMaintenanceDate}
																KeyboardButtonProps={{
																	"aria-label": "Change maintenance date",
																}}
															/>
														</MuiPickersUtilsProvider>
													</Grid>
													<Grid item xs={12} md={5}>
														<TextField
															id="maintenanceCommentField"
															label="Comment"
															size="small"
															variant="outlined"
															onChange={this.changeMaintenanceComment}
														/>
													</Grid>
													<Grid item xs={12} md={2}>
														<Button color="primary" variant="outlined" onClick={this.handleMaintenanceDateApply}>
															Apply
														</Button>
													</Grid>
												</Grid>
											</AccordionDetails>
										)}
									</Accordion>
									<Accordion
										expanded={this.state.expanded === "panel5"}
										onChange={this.handleAccordionChange("panel5")}
										disabled={this.props.lastLicenseHistory.action === "block-license" ? true : false}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
											<Typography className={classes.heading}>License expiration date</Typography>
											<Typography className={classes.secondaryHeading}>
												{this.props.lastLicenseHistory.expireDate != null ? this.props.lastLicenseHistory.expireDate : "Not set"}
											</Typography>
										</AccordionSummary>
										{this.props.user.role === ROLE_ADMIN && (
											<AccordionDetails>
												<Grid container justify="center" style={{ display: "flex", alignItems: "center" }}>
													<Grid item xs={12} md={5}>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															<KeyboardDatePicker
																margin="normal"
																id="expirationDatePicker"
																format="yyyy-MM-dd"
																minDate={this.state.currentDate}
																minDateMessage="Error! This date has already passed"
																value={
																	this.state.expiration >= this.state.currentDate
																		? this.state.expiration
																		: this.state.currentDate
																}
																onChange={this.changeExpirationDate}
																KeyboardButtonProps={{
																	"aria-label": "Change expiration date",
																}}
															/>
														</MuiPickersUtilsProvider>
													</Grid>
													<Grid item xs={12} md={5}>
														<TextField
															id="expirationCommentField"
															label="Comment"
															size="small"
															variant="outlined"
															onChange={this.changeExpirationComment}
														/>
													</Grid>
													<Grid item xs={12} md={2}>
														<Button color="primary" variant="outlined" onClick={this.handleExpirationDateApply}>
															Apply
														</Button>
													</Grid>
												</Grid>
											</AccordionDetails>
										)}
									</Accordion>
									{this.props.lastLicenseHistory.action === "block-license"
										? this.props.user.role === ROLE_ADMIN && (
												<Button className={classes.buttonSpacing} color="primary" variant="contained" onClick={this.handleUnblock}>
													Unblock
												</Button>
										  )
										: this.props.user.role === ROLE_ADMIN && (
												<Button className={classes.buttonSpacing} color="secondary" variant="contained" onClick={this.handleBlock}>
													Block
												</Button>
										  )}
								</Paper>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
							<Grid item xs={12} className={classes.contentSpacing}>
								<Accordion
									expanded={this.state.expandedRight === "rightPanel1"}
									onChange={this.handleRightAccordionChange("rightPanel1")}
									TransitionProps={{ unmountOnExit: true }}
								>
									{this.state.expandedRight !== "rightPanel1" && (
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="rightPanel1" id="rightPanel1">
											<Grid container justify="center">
												License history
											</Grid>
										</AccordionSummary>
									)}

									<TableContainer component={Paper}>
										<Table aria-label="simple table" stickyHeader={true}>
											<TableHead>
												<TableRow>
													<TableCell colSpan={4} align="center">
														License history
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell align="center">Action</TableCell>
													<TableCell align="center">Value</TableCell>
													<TableCell align="center">User</TableCell>
													<TableCell align="center">Entry date</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.props.licenseHistory
													.slice(
														this.state.page * this.state.rowsPerPage,
														this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
													)
													.map((row) => (
														<TableRow key={row.licHistId}>
															<TableCell component="th" scope="row" align="center">
																{row.action}
															</TableCell>
															<TableCell component="th" scope="row" align="center">
																{row.action === "block-license" && "Blocked"}
																{row.action === "unblock-license" && "Unblocked"}
																{row.action === "set-version" && row.allowedVersion.version}
																{row.action === "set-maintenance" && row.maintenanceDate}
																{row.action === "set-expiration" && row.expireDate}
															</TableCell>
															<TableCell align="center">{row.userId.email}</TableCell>
															<TableCell align="center">{moment(row.entryDate).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
										<TablePagination
											component={Paper}
											rowsPerPageOptions={[this.state.rowsPerPage]}
											count={this.props.licenseHistory.length}
											rowsPerPage={this.state.rowsPerPage}
											page={this.state.page}
											onChangePage={this.handleChangePage}
											onChangeRowsPerPage={this.handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</TableContainer>
								</Accordion>
								<Accordion
									expanded={this.state.expandedRight === "rightPanel2"}
									onChange={this.handleRightAccordionChange("rightPanel2")}
									TransitionProps={{ unmountOnExit: true }}
								>
									{this.state.expandedRight !== "rightPanel2" && (
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="rightPanel2" id="rightPanel2">
											<Grid container justify="center">
												Host history
											</Grid>
										</AccordionSummary>
									)}
									{!this.state.smallComponentLoading && (
										<MaterialTable
											icons={tableIcons}
											style={{ overflowX: "auto", width: "100%" }}
											columns={[
												{
													title: "Host hist",
													field: "hostHistoryActivationsId.hostHistId.hostHistId",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{ title: "Host", field: "hostHistoryActivationsId.hostHistId.hostHistoryId.hostId", editable: "never" },
												{
													title: "Entry date",
													field: "hostHistoryActivationsId.hostHistId.hostHistoryId.entryDate",
													render: (row) => (
														<span>
															{moment(row.hostHistoryActivationsId.hostHistId.hostHistoryId.entryDate).format(
																"YYYY-MM-DD HH:mm:ss"
															)}
														</span>
													),
													editable: "never",
												},
												{ title: "Source", field: "hostHistoryActivationsId.hostHistId.source", editable: "never" },
												{ title: "Action", field: "hostHistoryActivationsId.hostHistId.action", editable: "never" },
												{
													title: "Action result",
													field: "hostHistoryActivationsId.hostHistId.actionResult",
													editable: "never",
												},
												{
													title: "Error code",
													field: "hostHistoryActivationsId.hostHistId.errorCode",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "Product version",
													field: "hostHistoryActivationsId.hostHistId.productVersion",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "OS name",
													field: "hostHistoryActivationsId.hostHistId.osName",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "User name",
													field: "hostHistoryActivationsId.hostHistId.userName",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "User language",
													field: "hostHistoryActivationsId.hostHistId.userLanguage",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "IP address",
													field: "hostHistoryActivationsId.hostHistId.ipAddress",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "HDD0",
													field: "hostHistoryActivationsId.hostHistId.hdd0",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "Volume IDs",
													field: "hostHistoryActivationsId.hostHistId.volumeIds",
													editable: "never",
													hidden: true,
													hiddenByColumnsButton: true,
												},
												{
													title: "Floating",
													field: "floating",
													render: (row) => <span>{(row.floating === null && "Not specified") || (row.floating ? "yes" : "no")}</span>,
													editable: "never",
												},
												{
													title: "Expiration date",
													field: "expirationDate",
													render: (row) => (
														<span>
															{row.expirationDate !== null ? moment(row.expirationDate).format("YYYY-MM-DD HH:mm:ss") : "Not set"}
														</span>
													),
													editable: "never",
												},
											]}
											data={this.props.hostHistoryActivations}
											title="Host history"
											actions={[
												{
													icon: tableIcons.SyncIcon,
													tooltip: "Refresh",
													isFreeAction: true,
													onClick: (e) => {
														this.handleDataRefresh(e);
													},
												},
											]}
											options={{
												pageSize: 3,
												pageSizeOptions: [],
												actionsColumnIndex: -1,
												columnsButton: true,
											}}
										/>
									)}
									<Loader promiseTracker={usePromiseTracker} color={"#3d5e61"} background={"rgba(255,255,255,.5)"} />
								</Accordion>
							</Grid>
						</Grid>
					</Grid>
				</Dialog>
			)
		);
	}
}

CustomDialog.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.user,
		license: state.licenseReducer.license,
		licenseVariants: state.licenseVariantReducer.licenseVariants,
		licenseVersions: state.licenseVersionReducer.licenseVersions,
		licenseeClassifications: state.licenseeClassificationReducer.licenseeClassifications,
		expiringLicensesHistory: state.licenseHistoryReducer.expiringLicensesHistory,
		hostHistoryActivations: state.licenseHistoryReducer.hostHistoryActivations,
		licensesHistory: state.licenseHistoryReducer.licensesHistory,
		licenseHistory: state.licenseHistoryReducer.licenseHistory,
		lastLicenseHistory: state.licenseHistoryReducer.lastLicenseHistory,
		dialogOpen: state.dialogReducer.dialogOpen,
		licenseId: state.dialogReducer.licenseId,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		getAllLicenseVariants: () => dispatch(getAllLicenseVariants()),
		getAllLicenseVersions: () => dispatch(getAllLicenseVersions()),
		getAllLicenseeClassifications: () => dispatch(getAllLicenseeClassifications()),
		getAllExpiringLicensesHistory: () => dispatch(getAllExpiringLicensesHistory()),
		getLicenseHistoryByLicenseId: (license) => dispatch(getLicenseHistoryByLicenseId(license)),
		getLastLicenseHistoryByLicenseId: (license) => dispatch(getLastLicenseHistoryByLicenseId(license)),
		getLicenseHostHistoryActivations: (licenseId) => dispatch(getLicenseHostHistoryActivations(licenseId)),
		getLicenseById: (license) => dispatch(getLicenseById(license)),
		updateLicense: (licenseId, license) => dispatch(updateLicense(licenseId, license)),
		createLicenseHistory: (licenseHistory) => dispatch(createLicenseHistory(licenseHistory)),
		setSnackBar: (open, variant, message) => dispatch(setSnackBar(open, variant, message)),
		setDialog: (dialogOpen, licenseId) => dispatch(setDialog(dialogOpen, licenseId)),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomDialog)));
