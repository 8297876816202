import { SET_DIALOG } from "../constants/ActionTypes";

export function setDialog(dialogOpen, licenseId){
    return{
        type: SET_DIALOG,
        payload: {
            dialogOpen,
            licenseId
        }
    }
}