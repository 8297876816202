import { SET_LICENSEE_CLASSIFICATION, SET_LICENSEE_CLASSIFICATIONS } from "../constants/ActionTypes";

const initialState = {
    licenseeClassification: {},
    licenseeClassifications: [],
  };

  export default function licenseeClassificationReducer(state = initialState, action) {
    if (action.type === SET_LICENSEE_CLASSIFICATION) {
        return Object.assign({}, state, {
          licenseeClassification: action.payload
        });
      }
    if (action.type === SET_LICENSEE_CLASSIFICATIONS) {
      return Object.assign({}, state, {
        licenseeClassifications: action.payload
      });
    }
    return state;
  };