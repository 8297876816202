import React from "react";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import CustomSideMenu from "../../components/menus/CustomSideMenu";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import { getAllUsers, getUserByToken, createUser, updateUser, deleteUser } from "../../actions/UserActions";
import { JWT_TOKEN, ROLE_ADMIN, PASSWORD_REGEX, EMAIL_REGEX, USERNAME_REGEX } from "../../constants/Config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { Grid, Select, FormControl, Button, Dialog, DialogTitle, TextField, FormHelperText, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import { setSnackBar } from "../../actions/SnackBarActions";
import { logout } from "../../actions/UserActions";
import PropTypes from "prop-types";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	PasswordIcon: forwardRef((props, ref) => <VpnKeyIcon {...props} ref={ref} />),
};

const styles = (theme) => ({
	backdrop: {
		color: "#fff",
		position: "absolute",
		marginTop: 20,
		left: 260,
		right: 20,
		bottom: 60,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	button: {
		margin: 0,
		top: 20,
		right: 20,
		bottom: "auto",
		left: "auto",
		position: "absolute",
	},
	contentSpacing: {
		padding: "30px",
	},
});

class UserManagementPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addUser: false,
			changePassword: false,
			username: "",
			usernameError: "",
			email: "",
			emailError: "",
			password: "",
			passwordError: "",
			repeatPassword: "",
			repeatPasswordError: "",
			role: "",
			roleError: "",
			status: "",
			statusError: "",
			users: [],
			selectedUser: [],
			loading: true,
		};
	}

	componentDidMount() {
		if (this.props.user.role !== ROLE_ADMIN) {
			this.props.history.push("/login");
		} else {
			const proc = async () => {
				await trackPromise(this.props.getAllUsers());
			};
			proc().then((res) => {
				if (!sessionStorage.getItem(JWT_TOKEN)) {
					this.props.history.push("/login");
				} else {
					this.setState({ loading: false });
				}
			});
		}
	}

	changeAddUserFormState = (event, value) => {
		this.setState({ addUser: value });
	};

	changePasswordFormState = (event, user, value) => {
		this.setState({ selectedUser: user });
		this.setState({ changePassword: value });
	};

	changeUsername = (event) => {
		this.setState({ username: event.target.value });
	};

	handleUsernameValidation = (event) => {
		if (event.target.value.match(USERNAME_REGEX)) {
			this.setState({ usernameError: "" });
		} else {
			this.setState({ usernameError: "Error! Wrong username format" });
		}
	};

	changeEmail = (event) => {
		this.setState({ email: event.target.value });
	};

	handleEmailValidation = (event) => {
		const errorMessage = "Error! Wrong email format";
		try{
			if(event.match(EMAIL_REGEX)){
				return true;
			}
			else{
				return { isValid: false, helperText: errorMessage};
			}
		} catch{
			if (event.target.value.match(EMAIL_REGEX)) {
				this.setState({ emailError: "" });
			} else {
				this.setState({ emailError: errorMessage });
			}
		}
	}

	changePassword = (event) => {
		this.setState({ password: event.target.value });
	};

	handlePasswordValidation = (event) => {
		if (event.target.value.match(PASSWORD_REGEX)) {
			this.setState({ passwordError: "" });
		} else {
			this.setState({ passwordError: "Error! Wrong password format" });
		}
	};

	changeRepeatPassword = (event) => {
		this.setState({ repeatPassword: event.target.value });
	};

	handleRepeatPasswordValidation = (event) => {
		if (event.target.value === this.state.password) {
			this.setState({ passwordRepeatError: "" });
		} else {
			this.setState({ passwordRepeatError: "Error! Both passwords must match" });
		}
	};

	changeRole = (event) => {
		this.setState({ role: event.target.value });
	};

	handleRoleValidation = (event) => {
		if (event.target.value !== "") {
			this.setState({ roleError: "" });
		} else {
			this.setState({ roleError: "Error! This field is required" });
		}
	};

	changeStatus = (event) => {
		this.setState({ status: event.target.value });
	};

	handleStatusValidation = (event) => {
		if (event.target.value !== "") {
			this.setState({ statusError: "" });
		} else {
			this.setState({ statusError: "Error! This field is required" });
		}
	};

	handleCreateUser = (event) => {
		if (
			this.state.username !== "" &&
			this.state.usernameError === "" &&
			this.state.email !== "" &&
			this.state.emailError === "" &&
			this.state.password !== "" &&
			this.state.passwordError === "" &&
			this.state.role !== "" &&
			this.state.status !== ""
		) {
			const user = {
				username: this.state.username,
				email: this.state.email,
				password: this.state.password,
				role: this.state.role,
				isActive: this.state.status,
			};
			const proc = async () => {
				await trackPromise(this.props.createUser(user));
			};
			proc().then((res) => {
				this.setState({ addUser: false });
			});
		} else {
			this.props.setSnackBar(true, "error", "Error! Required fields must be filled and meet requirements");
		}
	};

	handleChangePassword = (event) => {
		if (this.state.password !== "" && this.state.repeatPassword !== "" && this.state.passwordError === "" && this.state.repeatPasswordError === "") {
			if (this.state.password === this.state.repeatPassword) {
				const user = {
					id: this.state.selectedUser.id,
					password: this.state.password,
					username: this.state.selectedUser.username,
					email: this.state.selectedUser.email,
					role: this.state.selectedUser.role,
					isActive: this.state.selectedUser.isActive,
				};
				const proc = async () => {
					await trackPromise(this.props.updateUser(user));
				};
				proc().then((res) => {
					if (this.props.user.id === user.id) {
						this.props.logout();
						this.props.setSnackBar(true, "success", "Your password changed successfully! Please log in again.");
						this.props.history.push("/login");
					} else {
						this.props.getAllUsers();
						this.setState({ changePassword: false });
					}
				});
			} else {
				this.props.setSnackBar(true, "error", "Error! Both passwords must match");
			}
		} else {
			this.props.setSnackBar(true, "error", "Error! Required fields must be filled");
		}
	};

	render() {
		const { classes } = this.props;
		return (
			<div>
				<Header />
				<CustomSideMenu
					sendView={
						<MaterialTable
							icons={tableIcons}
							style={{ marginBottom: 40 }}
							columns={[
								{ title: "ID", field: "id", editable: "never" },
								{
									title: "Username",
									field: "username",
									validate: rowData => rowData.username.match(USERNAME_REGEX) ? {isValid: true} : {isValid: false},
									editComponent: (props) => (
										<TextField
											disabled={props.rowData.username === "master"}
											type="text"
											placeholder="Username"
											value={props.value}
											inputProps={{ style: { fontSize: "14px" } }}
											onChange={(e) => props.onChange(e.target.value)}
											helperText={props.rowData.username.match(USERNAME_REGEX) ? "" : "Error! Wrong username format"}
											error={!props.rowData.username.match(USERNAME_REGEX)}
											FormHelperTextProps={{ error: true }}
										/>
									),
								},
								{ title: "Email", field: "email", validate: rowData => this.handleEmailValidation(rowData.email) },
								{
									title: "Role",
									field: "role",
									lookup: { administrator: "Administrator", user: "User" },
									editComponent: (props) => (
										<Select
											disabled={props.rowData.username === "master" || props.rowData.id === this.props.user.id}
											value={props.value}
											SelectDisplayProps={{ style: { fontSize: "13px" } }}
											onChange={(e) => props.onChange(e.target.value)}
										>
											<MenuItem value={"administrator"}>Administrator</MenuItem>
											<MenuItem value={"user"}>User</MenuItem>
										</Select>
									),
								},
								{
									title: "Status",
									field: "isActive",
									lookup: { true: "Active", false: "Inactive" },
									editComponent: (props) => (
										<Select
											disabled={props.rowData.username === "master" || props.rowData.id === this.props.user.id}
											value={props.value}
											SelectDisplayProps={{ style: { fontSize: "13px" } }}
											onChange={(e) => props.onChange(e.target.value)}
										>
											<MenuItem value={true}>Active</MenuItem>
											<MenuItem value={false}>Inactive</MenuItem>
										</Select>
									),
								},
							]}
							data={!this.state.loading ? this.props.users : this.state.users}
							title="Users management panel"
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20],
								actionsColumnIndex: -1,
							}}
							actions={[
								{
									icon: tableIcons.Add,
									tooltip: "Create new user",
									isFreeAction: true,
									onClick: (e, rowData) => {
										this.changeAddUserFormState(e, true);
									},
								},
								{
									icon: tableIcons.PasswordIcon,
									tooltip: "Change password",
									onClick: (e, rowData, state) => {
										this.changePasswordFormState(e, rowData, true);
									},
								},
							]}
							editable={{
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve, reject) => {
										setTimeout(() => {
											resolve();
											const proc = async () => {
												await trackPromise(this.props.updateUser(newData));
											};
											proc().then((res) => {
												if (this.props.user.id === oldData.id) {
													this.props.logout();
													this.props.setSnackBar(true, "success", "Your credentials updated successfully! Please log in again.");
													this.props.history.push("/login");
												} else {
													this.props.getAllUsers();
												}
											});
										}, 1000);
									}),
							}}
						/>
					}
				/>
				{this.state.addUser && (
					<Dialog aria-labelledby="addLicenseDialog" open={this.state.addUser} className={classes.backdrop} fullWidth maxWidth="sm">
						<DialogTitle id="addLicenseDialogTitle">Create new user</DialogTitle>
						<Fab size="small" className={classes.button} aria-label="add" onClick={(e) => this.changeAddUserFormState(false)}>
							<CloseIcon />
						</Fab>
						<Grid container className={classes.contentSpacing} justify="center">
							<TextField
								variant="outlined"
								margin="normal"
								size="small"
								required
								fullWidth
								id="username"
								label="Username"
								name="username"
								onChange={this.changeUsername}
								onBlur={this.handleUsernameValidation}
								helperText={this.state.usernameError}
								FormHelperTextProps={{ error: true }}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								size="small"
								required
								fullWidth
								id="email"
								label="Email"
								name="email"
								onChange={this.changeEmail}
								onBlur={this.handleEmailValidation}
								helperText={this.state.emailError}
								FormHelperTextProps={{ error: true }}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								size="small"
								type="password"
								required
								fullWidth
								id="password"
								label="Password"
								name="password"
								onChange={this.changePassword}
								onBlur={this.handlePasswordValidation}
								helperText={this.state.passwordError}
								FormHelperTextProps={{ error: true }}
							/>
							<FormControl
								size="small"
								margin="normal"
								fullWidth
								variant="outlined"
								onChange={this.handleRoleValidation}
								onBlur={this.handleRoleValidation}
							>
								<Select native={true} onChange={(e) => this.changeRole(e)}>
									<option aria-label="None" value="">
										-- Select role * --
									</option>
									<option aria-label="None" value="user" key="user">
										User
									</option>
									<option aria-label="None" value="administrator" key="administrator">
										Administrator
									</option>
								</Select>
								{this.state.roleError !== "" && <FormHelperText error={true}>{this.state.roleError}</FormHelperText>}
							</FormControl>
							<FormControl
								size="small"
								margin="normal"
								fullWidth
								variant="outlined"
								onChange={this.handleStatusValidation}
								onBlur={this.handleStatusValidation}
							>
								<Select native={true} onChange={(e) => this.changeStatus(e)}>
									<option aria-label="None" value="">
										-- Select status * --
									</option>
									<option aria-label="None" value={true} key="active">
										Active
									</option>
									<option aria-label="None" value={false} key="inactive">
										Inactive
									</option>
								</Select>
								{this.state.statusError !== "" && <FormHelperText error={true}>{this.state.statusError}</FormHelperText>}
							</FormControl>
							<Grid container justify="center">
								<Button type="submit" variant="contained" color="primary" margin="normal" className="submit" onClick={this.handleCreateUser}>
									Create
								</Button>
							</Grid>
						</Grid>
					</Dialog>
				)}
				{this.state.changePassword && (
					<Dialog aria-labelledby="addLicenseDialog" open={this.state.changePassword} className={classes.backdrop} fullWidth maxWidth="sm">
						<DialogTitle id="addLicenseDialogTitle">Change password: {this.state.selectedUser.email}</DialogTitle>
						<Fab size="small" className={classes.button} aria-label="add" onClick={(e) => this.changePasswordFormState(false)}>
							<CloseIcon />
						</Fab>
						<Grid container className={classes.contentSpacing} justify="center">
							<TextField
								variant="outlined"
								margin="normal"
								size="small"
								type="password"
								required
								fullWidth
								id="password"
								label="Password"
								name="password"
								onChange={this.changePassword}
								onBlur={this.handlePasswordValidation}
								helperText={this.state.passwordError}
								FormHelperTextProps={{ error: true }}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								size="small"
								type="password"
								required
								fullWidth
								id="repeatPassword"
								label="Repeat password"
								name="repeatPassword"
								onChange={this.changeRepeatPassword}
								onBlur={this.handleRepeatPasswordValidation}
								helperText={this.state.passwordRepeatError}
								FormHelperTextProps={{ error: true }}
							/>
							<Grid container justify="center">
								<Button
									type="submit"
									variant="contained"
									color="primary"
									margin="normal"
									className="submit"
									onClick={this.handleChangePassword}
								>
									Change
								</Button>
							</Grid>
						</Grid>
					</Dialog>
				)}
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.user,
		users: state.userReducer.users,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		getAllUsers: () => dispatch(getAllUsers()),
		getUserByToken: (token) => dispatch(getUserByToken(token)),
		createUser: (user) => dispatch(createUser(user)),
		updateUser: (user) => dispatch(updateUser(user)),
		deleteUser: (user) => dispatch(deleteUser(user)),
		setSnackBar: (open, variant, message) => dispatch(setSnackBar(open, variant, message)),
		logout: () => dispatch(logout()),
	};
}

UserManagementPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserManagementPage)));
