import { SET_LICENSE_VARIANT, SET_LICENSE_VARIANTS } from "../constants/ActionTypes";

const initialState = {
    licenseVariant: {},
    licenseVariants: [],
  };

  export default function licenseVariantReducer(state = initialState, action) {
    if (action.type === SET_LICENSE_VARIANT) {
        return Object.assign({}, state, {
          licenseVariant: action.payload
        });
      }
    if (action.type === SET_LICENSE_VARIANTS) {
      return Object.assign({}, state, {
        licenseVariants: action.payload
      });
    }
    return state;
  };