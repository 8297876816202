import axios from "axios";
import { SET_LICENSE_VARIANTS, CREATE_LICENSE_VARIANT, UPDATE_LICENSE_VARIANT, DELETE_LICENSE_VARIANT } from "../constants/ActionTypes";
import { JWT_TOKEN, JWT_REFRESH } from "../constants/Config";
import { setSnackBar } from "./SnackBarActions";
import { logout, refreshToken } from "./UserActions";

function setLicenseVariants(licenseVariants) {
	return {
		type: SET_LICENSE_VARIANTS,
		payload: licenseVariants,
	};
}

export function getAllLicenseVariants() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/products/variants/all`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setLicenseVariants(res.data));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}

export function createLicenseVariant(licenseVariant) {
	return function (dispatch) {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/products/variants/create`, licenseVariant, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "New product variant created successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseVariants());
				return {
					type: CREATE_LICENSE_VARIANT,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function updateLicenseVariant(licenseVariant) {
	return function (dispatch) {
		return axios
			.patch(`${process.env.REACT_APP_API_URL}/products/variants/update/${licenseVariant.id}`, licenseVariant, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "Product variant updated successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseVariants());
				return {
					type: UPDATE_LICENSE_VARIANT,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function deleteLicenseVariant(licenseVariant) {
	return function (dispatch) {
		return axios
			.delete(`${process.env.REACT_APP_API_URL}/products/variants/delete/${licenseVariant.id}`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "Product variant deleted successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseVariants());
				return {
					type: DELETE_LICENSE_VARIANT,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}
