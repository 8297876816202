import { SET_LICENSE_VERSION, SET_LICENSE_VERSIONS } from "../constants/ActionTypes";

const initialState = {
    licenseVersion: {},
    licenseVersions: [],
};

export default function licenseVersionReducer(state = initialState, action) {
    if (action.type === SET_LICENSE_VERSION) {
        return Object.assign({}, state, {
            licenseVersion: action.payload
        });
    }
    if (action.type === SET_LICENSE_VERSIONS) {
        return Object.assign({}, state, {
            licenseVersions: action.payload
        });
    }
    return state;
};