import { SET_LICENSE, SET_LICENSES } from "../constants/ActionTypes";

const initialState = {
    license: {},
    licenses: [],
  };

  export default function licenseReducer(state = initialState, action) {
    if (action.type === SET_LICENSE) {
        return Object.assign({}, state, {
          license: action.payload
        });
      }
    if (action.type === SET_LICENSES) {
      return Object.assign({}, state, {
        licenses: action.payload
      });
    }
    return state;
  };