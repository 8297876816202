import { SET_DIALOG } from "../constants/ActionTypes";

const initialState = {
    dialogOpen: false,
    licenseId: null,
  };

  export default function dialogReducer(state = initialState, action) {
    if (action.type === SET_DIALOG) {
      return Object.assign({}, state, {
        dialogOpen: action.payload.dialogOpen,
        licenseId: action.payload.licenseId
      });
    }
    return state;
  };