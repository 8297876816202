import axios from "axios";
import { SET_LICENSE, SET_LICENSES, CREATE_LICENSE } from "../constants/ActionTypes";
import { JWT_TOKEN, JWT_REFRESH } from "../constants/Config";
import { setSnackBar } from "./SnackBarActions";
import { logout, refreshToken } from "./UserActions";
import { createLicenseHistory } from "./LicenseHistoryActions";

function setLicense(license) {
	return {
		type: SET_LICENSE,
		payload: license,
	};
}

function setLicenses(licenses) {
	return {
		type: SET_LICENSES,
		payload: licenses,
	};
}

export function getAllLicenses() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/all`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setLicenses(res.data));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}

export function getLicenseById(licenseId) {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/` + licenseId, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setLicense(res.data));
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function createLicense(license, licenseHistory) {
	return function (dispatch) {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/licenses/create`, license, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setLicense(res.data));
				const history = {
					licenseId: { licenseId: res.data.licenseId },
					allowedVersion: licenseHistory.allowedVersion,
					maintenanceDate: licenseHistory.maintenanceDate,
					expireDate: licenseHistory.expireDate,
					userId: licenseHistory.userId,
				};
				dispatch(createLicenseHistory(history));
				dispatch(setSnackBar(true, "success", "New license created successfully!"));
				return {
					type: CREATE_LICENSE,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function updateLicense(licenseId, license) {
	return function (dispatch) {
		return axios
			.patch(`${process.env.REACT_APP_API_URL}/licenses/update/${licenseId}`, license, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "License updated successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getLicenseById(licenseId));
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}
