import axios from "axios";
import {
	SET_LICENSEE_CLASSIFICATIONS,
	CREATE_LICENSEE_CLASSIFICATION,
	UPDATE_LICENSEE_CLASSIFICATION,
	DELETE_LICENSEE_CLASSIFICATION,
} from "../constants/ActionTypes";
import { JWT_TOKEN, JWT_REFRESH } from "../constants/Config";
import { setSnackBar } from "./SnackBarActions";
import { logout, refreshToken } from "./UserActions";

function setLicenseeClassifications(licenseeClassifications) {
	return {
		type: SET_LICENSEE_CLASSIFICATIONS,
		payload: licenseeClassifications,
	};
}

export function getAllLicenseeClassifications() {
	return function (dispatch) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/licenses/classification/all`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(refreshToken(res.headers));
				dispatch(setLicenseeClassifications(res.data));
			})
			.catch((error) => {
				if (error.response.status === 401 || error.response.status === 403) {
					dispatch(logout());
					dispatch(setSnackBar(true, "error", "Session expired! Log in again."));
				} else {
					dispatch(setSnackBar(true, "error", "Error ocurred!"));
				}
			});
	};
}

export function createLicenseeClassification(licenseeClassification) {
	return function (dispatch) {
		return axios
			.post(`${process.env.REACT_APP_API_URL}/licenses/classification/create`, licenseeClassification, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "New licensee classification created successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseeClassifications());
				return {
					type: CREATE_LICENSEE_CLASSIFICATION,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function updateLicenseeClassification(licenseeClassification) {
	return function (dispatch) {
		return axios
			.patch(
				`${process.env.REACT_APP_API_URL}/licenses/classification/update/${licenseeClassification.licenseeClassificationId}`,
				licenseeClassification,
				{ headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) } }
			)
			.then((res) => {
				dispatch(setSnackBar(true, "success", "Licensee classification updated successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseeClassifications());
				return {
					type: UPDATE_LICENSEE_CLASSIFICATION,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}

export function deleteLicenseeClassification(licenseeClassification) {
	return function (dispatch) {
		return axios
			.delete(`${process.env.REACT_APP_API_URL}/licenses/classification/delete/${licenseeClassification.licenseeClassificationId}`, {
				headers: { Authorization: sessionStorage.getItem(JWT_TOKEN), Refresh: sessionStorage.getItem(JWT_REFRESH) },
			})
			.then((res) => {
				dispatch(setSnackBar(true, "success", "Licensee classification deleted successfully!"));
				dispatch(refreshToken(res.headers));
				dispatch(getAllLicenseeClassifications());
				return {
					type: DELETE_LICENSEE_CLASSIFICATION,
				};
			})
			.catch((error) => {
				dispatch(setSnackBar(true, "error", error.response.data.message));
			});
	};
}
