import React from 'react';
import { withRouter } from "react-router-dom";
import { JWT_TOKEN } from './constants/Config';
import Header from './components/sections/Header';
import Footer from './components/sections/Footer';
import ExpirationManagementPage from './views/License/ExpirationManagementPage';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        if (!sessionStorage.getItem(JWT_TOKEN)) {
            this.props.history.push('/login')
        }
    }

    render() {
        return (
            <div>
                <Header />
                    <ExpirationManagementPage />
                <Footer />
            </div>
        );
    }
}

export default withRouter(HomePage);